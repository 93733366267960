/* We might want to provide fallback fonts for old browsers that don't support variable fonts */
/* Take a look at the website styles on how to do this */

@font-face {
  font-family: 'Faro-Regular';
  font-display: swap;
  src: url('../Assets/Fonts/Faro-Variable.woff2') format('woff2');
  font-style: normal;
  font-weight: 400 700; /* Regular weight */
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Faro-Medium';
  font-display: swap;
  src: url('../Assets/Fonts/Faro-Variable.woff2') format('woff2');
  font-style: normal;
  font-weight: 500; /* Medium weight */
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Faro-SemiBold';
  font-display: swap;
  src: url('../Assets/Fonts/Faro-Variable.woff2') format('woff2');
  font-style: normal;
  font-weight: 600; /* Medium weight */
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Faro-Bold';
  font-display: swap;
  src: url('../Assets/Fonts/Faro-Variable.woff2') format('woff2');
  font-style: normal;
  font-weight: 700; /* Bold weight */
  text-rendering: optimizeLegibility;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: manipulation;
}

html {
  font-size: 10px;
}

body {
  font-family: 'Faro-Regular', ui-sans-serif, system-ui, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F5F6F7;
}

#root > div {
  display: flex;
  flex-direction: column;
}

/* Reset input styles */
input[type='text'],
input[type='email'],
input[type='tel'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Prevent showing focus outline when trigered via the mouse */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
